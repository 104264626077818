@import '~@angular/material/theming';

@mixin custom-tool-tip-component-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .custom-tooltip {
    color: mat-color($foreground, tooltip);
    background: mat-color($background, tooltip);
  }
}
