@import '~@angular/material/theming';

// mixin name will be used in main style.scss
@mixin perdoruesit-component-theme($theme) {
  $background: map-get($theme, background);

  app-perdoruesit {
    .perdoruesit {
      .perdoruesi {
        button {
          .wrap {
            background-color: mat-color($background, app-bar);
            box-shadow: 0 0 0px 3px #ffac16;
            &.online {
              box-shadow: 0 0 0px 3px #14d514;
            }
            .mat-badge-content {
              background-color: #c88409;
            }
            &.online .mat-badge-content {
              background-color: #08a608 !important;
            }
          }
        }
      }
    }
  }
}
