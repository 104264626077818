@mixin admin-component-theme($theme) {
  
  $primary: map-get($theme, primary);

  mat-card.navigation {
    mat-nav-list {
      mat-list-item {
        &.active {
          color: mat-color($primary);
        }
      }
    }
  }
}
