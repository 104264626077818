@import '~@angular/material/theming';

@mixin as-search-and-replace-highight-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  as-hl-textarea {
    .mat-input-element.readonly {
      color: mat-color($foreground, text);
    }
    .hl-textarea-highlight mark {
      background-color: mat-color($background, mark);
      &.selected {
        background-color: mat-color($background, mark-selected);
      }
    }
  }
}

