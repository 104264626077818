@import '~@angular/material/theming';

@mixin editor-view-modecomponent-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-editor-view-mode {
    .pershkrimi {
      i, a {
        color: mat-color($primary);
      }
    }
  }
}
