@import '~@angular/material/theming';

@mixin word-list-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-word-list {
    .results {
      border-bottom: solid 1px mat-color($foreground, divider);
      color: mat-color($foreground, text);

      .scrollTo {
        color: mat-color($foreground, text);
      }
    }
    .notFoundInList {
      background-color: mat-color($background);
      border-bottom: solid 1px mat-color($foreground, divider);
    }
  }
}

