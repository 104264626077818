@import '~@angular/material/theming';

@mixin as-bottom-sheet-component-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  as-bottom-sheet {
    .handle {
      background-color: mat-color($foreground, divider);
      mat-icon {
        color: mat-color($foreground, text);
      }
    }
  }
}
