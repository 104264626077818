@import '~@angular/material/theming';

@mixin editor-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-editor {
    form.ng-dirty {
      &.ng-valid::before {
        border-color: mat-color($primary);
      }
      &.ng-invalid::before {
        border-color: mat-color($warn);
      }
      &.saving::before {
        border-color: mat-color($accent);
      }
    }

    .editor-head {
      .fjala {
        mat-form-field {
          .mat-form-field-outline-thick {
            color: mat-color($primary);
          }
          &.mat-form-field-disabled .mat-form-field-outline {
            color: mat-color($foreground, outline-disabled) !important;
          }
          &.ng-dirty.ng-invalid .mat-form-field-outline {
            color: mat-color($warn);
          }
        }
      }
      .isExcluded div {
        background-color: mat-color($warn)
      }
      .info {
        .id,
        .pjesaLigjerates {
          color: mat-color($primary);
        }
      }
    }
    .editor-body {
      .mat-focused .mat-form-field-label {
        color: mat-color($accent);
      }
      .textarea {
        .mat-form-field-label {
          color: mat-color($primary);
        }
      }
      .mat-form-field-disabled .mat-form-field-label {
        color: mat-color($foreground, disabled) !important;
      }
    }
  }
}
