// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Import material theming functions
@import '~@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'variables.scss';

// import custom componenet themes
// unofficial naming convention to support nice ordering of files in IDE
// to see theme under the original style file of the componenent
@import 'app/core/header/header.component.scss-theme.scss';
@import 'app/core/header/perdoruesit/perdoruesit.component.scss-theme.scss';
@import 'app/modules/admin/admin.component.scss-theme.scss';
@import 'app/modules/admin/permissions/add-edit-permissions/add-edit-permissions.component.scss-theme.scss';

@import 'app/modules/home/components/word-list/word-list.component.scss-theme.scss';
@import 'app/modules/home/components/word-list/word-list-collapsed/word-list-collapsed.component.scss-theme.scss';
@import 'app/modules/home/components/word-list/word-list-item/word-list-item.component.scss-theme.scss';
@import 'app/modules/home/components/editors-panel/editor/editor.component.scss-theme.scss';
@import 'app/modules/home/components/editors-panel/editor/editor-view-mode/editor-view-mode.component.scss-theme.scss';
@import 'app/modules/home/components/right-panel/njoftimet/njoftimet.component.scss-theme.scss';

@import 'app/modules/shembujt/components/shembujt-list/shembujt-list.component.scss-theme.scss';
@import 'app/modules/shembujt/components/shembujt-list/shembulli-item/shembulli-item.component.scss-theme.scss';

@import 'app/modules/proverba/components/proverba-list/proverba-list.component.scss-theme.scss';
@import 'app/modules/proverba/components/proverba-list/proverba-item/proverba-item.component.scss-theme.scss';

@import 'app/modules/frazeologjite/components/frazeologjite-list/frazeologjite-list.component.scss-theme.scss';
@import 'app/modules/frazeologjite/components/frazeologjite-list/frazeologjia-item/frazeologjia-item.component.scss-theme.scss';

@import 'app/modules/fjala-huaj/components/fjala-huaj-list/fjala-huaj-list.component.scss-theme.scss';
@import 'app/modules/fjala-huaj/components/fjala-huaj-list/fjala-huaj-item/fjala-huaj-item.component.scss-theme.scss';

@import 'app/modules/fjala-gabim/components/fjala-gabim-list/fjala-gabim-list.component.scss-theme.scss';
@import 'app/modules/fjala-gabim/components/fjala-gabim-list/fjala-gabim-item/fjala-gabim-item.component.scss-theme.scss';

@import 'app/shared/components/as-query-builder/as-query-builder.component.scss-theme.scss';
@import 'app/shared/components/custom-tool-tip/custom-tool-tip-component.scss-theme.scss';
@import 'app/shared/components/as-ck-editor/as-ck-editor.component.scss-theme.scss';
@import 'app/shared/components/as-card/as-card.component.scss-theme.scss';
@import 'app/shared/components/as-bottom-sheet/as-bottom-sheet.component.scss-theme.scss';
@import 'app/shared/modules/as-search-and-replace/components/as-search-and-replace-box/as-search-and-replace-box.component.scss-theme.scss';
@import 'app/shared/modules/as-search-and-replace/components/as-search-and-replace-highight-textarea/as-search-and-replace-highight-textarea.component.scss-theme.scss';

// you only have to add additional components here (instead of in every theme class)
@mixin custom-components-theme($theme) {
  @include header-component-theme($theme);
  @include perdoruesit-component-theme($theme);
  @include admin-component-theme($theme);
  @include admin-add-edit-permissions-theme($theme);

  @include word-list-collapsed-component-theme($theme);
  @include word-list-component-theme($theme);
  @include word-list-item-component-theme($theme);
  @include editor-component-theme($theme);
  @include editor-view-modecomponent-theme($theme);
  @include njoftimet-component-theme($theme);

  @include shembujt-list-component-theme($theme);
  @include shembulli-list-item-component-theme($theme);

  @include proverba-list-component-theme($theme);
  @include proverba-list-item-component-theme($theme);

  @include frazeologjite-list-component-theme($theme);
  @include frazeologjia-list-item-component-theme($theme);

  @include fjala-huaj-list-component-theme($theme);
  @include fjala-huaj-list-item-component-theme($theme);

  @include fjala-gabim-list-component-theme($theme);
  @include fjala-gabim-list-item-component-theme($theme);

  @include as-query-builder-component-theme($theme);
  @include custom-tool-tip-component-theme($theme);
  @include as-ck-editor-component-theme($theme);
  @include as-card-component-theme($theme);
  @include as-bottom-sheet-component-theme($theme);
  @include as-search-and-replace-box-component-theme($theme);
  @include as-search-and-replace-highight-component-theme($theme);

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // global styles
  .primary-color {
    color: mat-color($primary);
  }
  .accent-color {
    color: mat-color($accent);
  }
  .warn-color {
    color: mat-color($warn);
  }
  .success-color {
    color: #0eb30e;
  }
  .disabled-color {
    color: mat-color($foreground, disabled);
  }

  as-split > .as-split-gutter,
  as-split > .as-split-gutter > .as-split-gutter-icon {
    background-color: mat-color($foreground, dividers) !important;
  }
  .section-header {
    background-color: mat-color($background, status-bar);
    // background-color: mat-color($primary, 0.1);
  }

  ::-webkit-scrollbar-track {
    background-color: mat-color($background, scrollbar-track);
  }
  ::-webkit-scrollbar-thumb:vertical {
    background-color: mat-color($background, scrollbar-thumb);
    border-color: mat-color($background, scrollbar-thumb);
  }
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: mat-color($background, scrollbar-thumb);
    border-color: mat-color($background, scrollbar-thumb);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: mat-color($background, scrollbar-thumb-hover);
    border-color: mat-color($background, scrollbar-thumb-hover);
  }

  .gray-wrap {
    background-color: mat-color($background, gray-wrap);
  }

  .welcome-screen {
    background-color: mat-color($background, background);
  }

  .footer {
    .shortcuts {
      > .buttons {
        button {
          color: mat-color($foreground, text);
          background-color: mat-color($background, app-bar);
          border-top: solid 2px mat-color($foreground, divider);
          border-right: solid 1px mat-color($foreground, divider);

          &:last-child {
            border-right: none;
          }
          &:hover {
            background-color: mat-color($background);
          }
          .activePresetIndicator {
            background-color: mat-color($accent);
          }
          &:disabled {
            color: mat-color($foreground, disabled-text);
          }
        }
      }
    }
    .footerWindow {
      background-color: mat-color($background, dialog);
      border-color: mat-color($foreground, divider);
    }
  }

  .drag-and-drop-item.editing {
    background: mat-color($background, background);
  }

  .mat-ripple-element {
    background-color: mat-color($primary, 0.1);
  }

  .white-mat-spinner circle {
    stroke: mat-color($foreground, tooltip);
  }

  .chips-list {
    .chips-item {
      border-color: mat-color($primary);
      color: mat-color($primary);

      &.new-item {
        background-color: mat-color($primary);
        color: white;
      }
      &.disabled {
        border-color: mat-color($foreground, disabled);
        color: mat-color($foreground, disabled);
      }

      &.orange {
        border-color: #d7a03b;
        color: #d7a03b;

        &.new-item {
          background-color: #d7a03b;
          color: #333333;
        }
      }
      &.green {
        border-color: #257e2d;
        color: #257e2d;

        &.new-item {
          background-color: #257e2d;
          color: white;
        }
      }
    }
  }

  .drag-and-drop-editor-item {
    background: mat-color($background, background);
    border-color: mat-color($foreground, divider);

    &.new-item {
      background: mat-color($background, status-bar);
    }
    &.disabled {
      .order-number,
      .input {
        color: mat-color($foreground, disabled);
      }
    }
  }

  /***** KOMENTET DIALOG *****/
  .komentet-wrapper {
    .content {
      .items {
        .group {
          .item {
            .pershkrimi {
              background: mat-color($background, tooltip);
              color: mat-color($foreground, tooltip);
            }

            &.self {
              .pershkrimi {
                background: mat-color($primary);
              }
            }
          }
        }
      }
    }
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

.light-theme {
  @include mat.all-component-themes($lex-theme);

  // include custom components theme mixin once per theme class
  @include custom-components-theme($lex-theme);
}

.dark-theme {
  @include mat.all-component-colors($lex-dark-theme);

  // include custom components theme mixin once per theme class
  @include custom-components-theme($lex-dark-theme);
}
