@import '~@angular/material/theming';

@mixin as-card-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  as-card {
    .as-card-wrapper {
      .as-card-label {
        color: mat-color($primary);
      }
      .as-card-outline {
        color: mat-color($foreground, outline);
      }
      .as-card-outline-thick { 
        color: mat-color($foreground, outline-thick);
      }
      &.active {
        .as-card-outline {
          color: mat-color($primary);
        }
      }
      &.disabled {
        .as-card-label {
          color: mat-color($foreground, disabled);
        }
        .as-card-outline {
          color: mat-color($foreground, outline-disabled);
        }
      }
    }
  }
}
