@import '~@angular/material/theming';

@mixin as-query-builder-component-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  as-query-builder {
    .q-empty-warning {
      color: mat-color($foreground, text) !important;
    }
    .q-rule,
    .q-ruleset {
      border: 1px solid mat-color($foreground, divider) !important;
      background-color: transparent !important;
    }
  }
}
