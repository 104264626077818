@import '~@angular/material/theming';

@mixin fjala-huaj-list-item-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-fjala-huaj-item {
    border-bottom: solid 1px mat-color($foreground, dividers);
    color: mat-color($foreground, text);

    .right {
      background-color: mat-color($background, background);
      box-shadow: -10px 0 10px 0px mat-color($background, background);
    }
    &:hover {
      background-color: mat-color($background, word-hover);

      .right {
        background-color: mat-color($background, word-hover);
        box-shadow: -10px 0 10px 0px mat-color($background, word-hover);
      }
    }
    &.active {
      background-color: mat-color($background, word-active);
      .right {
        background-color: mat-color($background, word-active);
        box-shadow: -10px 0 10px 0px mat-color($background, word-active);
      }
    }
    &.locked {
      background-color: mat-color($background, locked-word);
      .right {
        background-color: mat-color($background, locked-word);
        box-shadow: -10px 0 10px 0px mat-color($background, locked-word);
      }
      &.active {
        background-color: mat-color($background, locked-active-word);
        .right {
          background-color: mat-color($background, locked-active-word);
          box-shadow: -10px 0 10px 0px mat-color($background, locked-active-word);
        }
      }
    }
    &.highlighted:before {
      background: mat-color($accent);
    }
    &:focus {
      outline-color: mat-color($primary);
    }
  }

  .custom-tooltip.word-list-comments {
    background: mat-color($background, card);
    color: mat-color($foreground, text);
    box-shadow: 0px 11px 15px -7px rgb(51 51 51 / 20%), 0px 24px 38px 3px rgb(51 51 51 / 14%), 0px 9px 46px 8px rgb(51 51 51 / 12%);

    .items {
      .group {
        .item {
          .pershkrimi {
            background: mat-color($background, tooltip);
            color: mat-color($foreground, tooltip);
          }

          &.self {
            .pershkrimi {
              background: mat-color($primary);
            }
          }
        }
      }
    }
  }
}
