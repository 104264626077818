@import '~@angular/material/theming';

// mixin name will be used in main style.scss
@mixin header-component-theme($theme) {
  
  // retrieve variables from theme 
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  
  // all of these variables contain many additional variables

  app-header {
    background-color: mat-color($background, app-bar);
    border-bottom: 3px solid mat-color($foreground, divider);
    
    .light-icon {
      color: mat-color($accent);
    }
  }
}
