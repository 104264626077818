@mixin as-ck-editor-component-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  ckeditor .ck.ck-reset_all,
  .ck.ck-reset_all * {
    color: mat-color($foreground, text);
  }
  .ck.ck-toolbar {
    background-color: mat-color($background, status-bar);
  }
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover {
    background-color: mat-color($background, hover);
  }
  .ck.ck-button:not(.ck-disabled):active,
  a.ck.ck-button:not(.ck-disabled):active,
  .ck.ck-button.ck-on:not(.ck-disabled):active,
  a.ck.ck-button.ck-on:not(.ck-disabled):active {
    background-color: mat-color($background, hover);
    box-shadow: none;
    border-color: mat-color($primary);
  }
  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on,
  .ck.ck-button.ck-on:not(.ck-disabled):hover,
  a.ck.ck-button.ck-on:not(.ck-disabled):hover {
    background-color: mat-color($background, focused-button);
  }

  .ck.ck-dropdown__panel {
    background-color: mat-color($background, background);
  }
  .ck.ck-list {
    background-color: mat-color($background, background);
  }
  .ck.ck-list__item .ck-button.ck-on,
  .ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled) {
    background-color: mat-color($primary);
  }
  .ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
    background-color: mat-color($background, hover);
  }
  .ck.ck-tooltip .ck-tooltip__text {
    background-color: mat-color($background, tooltip);
    color: mat-color($foreground, text);
  }
  .ck.ck-tooltip.ck-tooltip_s .ck-tooltip__text:after,
  .ck.ck-tooltip.ck-tooltip_sw .ck-tooltip__text:after {
    border-bottom-color: mat-color($background, tooltip);
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background: none;
  }
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border-color: mat-color($primary);
  }
}
