* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  padding-top: 53px;
  overflow: hidden;
}

.body-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb:vertical {
  background-image: url('assets/images/scrollbar_bg_v.png');
  border-top: solid 5px;
  border-bottom: solid 5px;
}
::-webkit-scrollbar-thumb:horizontal {
  background-image: url('assets/images/scrollbar_bg_h.png');
  border-left: solid 5px;
  border-right: solid 5px;
}

.as-horizontal,
.as-vertical {
  > .as-split-gutter {
    position: relative;
    > .as-split-gutter-icon {
      position: absolute;
      top: auto;
      left: auto;
      background-color: #e6e6e6;
      opacity: 0;
      transition: opacity 0.1s ease;
      z-index: 99;

      &:hover {
        opacity: 1;
      }
    }
  }
}
.as-horizontal > .as-split-gutter > .as-split-gutter-icon {
  width: 5px !important;
  background-image: url('assets/images/thumb-horizontal.png') !important;
}
.as-vertical > .as-split-gutter > .as-split-gutter-icon {
  height: 5px !important;
  background-image: url('assets/images/thumb-vertical.png') !important;
}

.gray-wrap {
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;
  padding-bottom: 50px;
}

.section-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.pin-btn {
  mat-icon {
    transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(45deg);
  }
  &.pinned mat-icon {
    transform: rotate(0deg);
  }
}

.section-header {
  width: 100%;
  height: 42px;
  text-align: left;
  position: relative;
  padding: 0 15px;
  padding-right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  
  .buttons {
    display: flex;
  }

  .fjalori {
    display: flex;
    gap: 10px;
    flex-shrink: 1;
    overflow: hidden;
    align-items: center;

    img {
      height: 32px;
      max-width: 80px;
      object-fit: contain;
      pointer-events: none;
      user-select: none;
    }
  }
  .title {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    mat-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px;
    }
  }
}

.section-body {
  height: calc(100% - 42px);
  overflow: auto;
}

.footer {
  position: relative;
  z-index: 100;

  .shortcuts {
    position: relative;
    width: 100%;
    height: 40px;
    user-select: none;

    & > .buttons {
      position: relative;
      z-index: 10;
      // background: #ffffff;
      display: flex;

      button {
        width: 100%;
        padding: 0 15px;
        padding-right: 45px;
        height: 40px;
        text-align: left;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        border-bottom: none;
        border-left: none;
        border-radius: 0;

        .badge {
          padding-right: 4px;
          display: inline-block;
          line-height: 14px;
          max-width: calc(100% - 18px);
        }
        .text {
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .mat-button-wrapper {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          display: block;
        }

        mat-icon {
          position: absolute;
          right: 10px;
          top: 8px;
          transition: transform 0.2s ease;
        }

        &.open {
          mat-icon {
            transform: rotate(180deg);
          }
        }

        &[disabled] {
          cursor: default;
          pointer-events: none;

          mat-icon {
            color: #d4d4d4;
          }
        }
      }
    }
  }

  .footerWindow {
    position: absolute;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: auto;
    transform: translateY(-100%);
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
    overflow-y: auto;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: solid 1px;
    border-bottom: none;
    .footer-pjesa-ligjerates {
      padding: 10px;
    }
    .footer-fusha-perdorimit {
      padding: 10px;
      min-height: 100%;
      height: 250px;
      as-fusha-perdorimit {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.page-title {
  position: relative;
  width: 100%;
  margin-top: 20px;
  text-align: center;

  button {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.no-mouse-events {
  pointer-events: none;
}

.drag-and-drop-list {
  height: 100%;
  overflow-y: auto;
}

.drag-and-drop-item {
  position: relative;
  display: flex;
  height: 36px;

  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    transition: all 0.2s ease;

    .title {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      user-select: none;
    }

    .buttons {
      display: flex;

      button {
        height: 36px;
        width: 36px;
        mat-icon {
          line-height: 20px;
        }
        &.visibility-btn {
          mat-icon {
            opacity: 0.5;
          }
          &.visible {
            mat-icon {
              opacity: 1;
            }
          }
        }
      }
      .cdk-drag-handle {
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        cursor: grab;
      }
    }
  }

  &.cdk-drag-preview {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  }

  &.cdk-drag-placeholder {
    opacity: 0;
  }

  &.checkbox-drag-and-drop-item {
    .mat-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transition: all 0.2s ease;

      label {
        margin-bottom: 0;
        padding: 10px;
      }
      .mat-checkbox-ripple {
        left: calc(50% - 18px);
        top: calc(50% - 18px);
        height: 36px;
        width: 36px;
        .mat-ripple-element {
          left: 0 !important;
          top: 0 !important;
          width: 36px !important;
          height: 36px !important;
        }
      }
    }
    .inner {
      padding-left: 40px;
    }

    &.editing {
      .mat-checkbox {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
      }

      .inner {
        padding-left: 10px;
      }
    }
  }
  &.slide-toggle-drag-and-drop-item {
    .mat-slide-toggle {
      flex: 1;
      height: 100%;
      padding-left: 8px;
      opacity: 1;
      overflow: hidden;

      .mat-slide-toggle-ripple {
        left: calc(50% - 18px);
        top: calc(50% - 18px);
        height: 36px;
        width: 36px;
        .mat-ripple-element {
          left: 0 !important;
          top: 0 !important;
          width: 36px !important;
          height: 36px !important;
        }
      }
      .mat-slide-toggle-label {
        transition: all 0.2s ease;

        .mat-slide-toggle-bar {
          transition: all 0.2s ease;
        }
      }
      &.mat-disabled .mat-slide-toggle-bar {
        opacity: 0.38;
      }
    }
    &.editing {
      .mat-slide-toggle {
        flex: 1;
        padding-left: 2px;

        .mat-slide-toggle-label {
          transform: translateX(-36px);
          .mat-slide-toggle-bar {
            opacity: 0;
          }
          .mat-slide-toggle-content {
            margin-right: -30px;
          }
        }
      }
    }
  }
  &.radio-button-drag-and-drop-item {
    .mat-radio-button {
      display: flex;
      flex: 1;
      height: 100%;
      padding-left: 8px;
      opacity: 1;
      overflow: hidden;

      .mat-radio-ripple {
        left: calc(50% - 18px);
        top: calc(50% - 18px);
        height: 36px;
        width: 36px;
        .mat-ripple-element {
          left: 0 !important;
          top: 0 !important;
          width: 36px !important;
          height: 36px !important;
        }
      }
      .mat-radio-label {
        transition: all 0.2s ease;

        .mat-radio-container {
          transition: all 0.2s ease;
        }
        .mat-radio-label-content {
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
      }
    }
    &.editing {
      .mat-radio-button {
        flex: 1;
        padding-left: 2px;

        .mat-radio-label {
          transform: translateX(-20px);
          .mat-radio-container {
            opacity: 0;
          }
          .mat-radio-label-content {
            margin-right: -20px;
          }
        }
      }
    }
  }
}

.drag-and-drop-list.cdk-drop-list-dragging .drag-and-drop-item:not(.cdk-drag-placeholder) {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

.no-items {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 15px;
}

h2.mat-dialog-title {
  margin-top: -14px;
  margin-bottom: 12px;
}

.mat-badge-hidden-content {
  .mat-badge-content {
    font-size: 0;
    width: 12px !important;
    height: 12px !important;
    line-height: 12px !important;
    top: -6px !important;
  }
  &.mat-badge-after .mat-badge-content {
    right: -16px !important;
  }
  &.mat-badge-overlap .mat-badge-content {
    right: -6px !important;
  }
}

.no-underline-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-flex {
      align-items: center;
    }
    .mat-form-field-infix {
      padding-top: 2px;
    }
  }
  .mat-form-field-underline {
    display: none;
  }

  .search-icon {
    height: 100%;
  }
}
.mat-tooltip-panel {
  pointer-events: none;
}

.mat-autocomplete-panel {
  mat-option {
    .mat-option-text {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      .top {
        display: flex;
        gap: 6px;
        width: 100%;
        height: 20px;
        line-height: 20px;

        .title {
          font-size: 14px;
          font-weight: 500;
        }
        .category {
          font-size: 13px;
        }
      }
      .bot {
        display: flex;
        gap: 6px;
        width: 100%;
        height: 20px;
        line-height: 20px;

        .description {
          font-size: 12px;
        }
      }
      .id {
        position: absolute;
        top: 5px;
        right: 10px;
        opacity: .6;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

.chips-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0 -4px 8px -4px;

  .chips-item {
    border: solid 1px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    user-select: none;
    cursor: pointer;
    font-size: 13px;

    &.sortable-ghost {
      opacity: 0.2;
    }

    span {
      display: inline-block;
    }
    button {
      display: block;
      width: 20px;
      height: 26px;
      line-height: 22px;
      margin-right: 4px;

      mat-icon {
        width: 100%;
        font-size: 18px;
      }
      &.arrow {
        margin-left: 2px;
        margin-right: 0;
        mat-icon {
          font-size: 20px;
        }
      }
    }
  }
}

.chip-baza-hover {
  display: flex;
  flex-direction: column;
}

.drag-and-drop-editor-list {
  margin: 0 -10px 1px;
}
.drag-and-drop-editor-item {
  display: flex;
  align-items: center;
  border-top: solid 1px;
  padding: 3px 6px;
  border-radius: 4px;

  .order-number {
    margin-right: 4px;
  }
  .input {
    flex: 1;
    line-height: 22px;
    white-space: pre-line;

    > span {
      display: inline-block;
    }
    as-hl-textarea {
      display: block;
      width: 100%;
      margin-bottom: -2px;
    }
  }
  .drag-handle,
  .edit-btn,
  .delete-btn {
    width: 34px;
    padding: 0;
    height: 22px;
    min-width: auto;
    line-height: 34px;

    mat-icon {
      font-size: 22px;
      line-height: 10px;
    }
  }
  .drag-handle {
    cursor: grab;
  }

  &.cdk-drag-preview {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  }

  &.cdk-drag-placeholder {
    opacity: 0;
  }
}
.drag-and-drop-editor-list.cdk-drop-list-dragging .drag-and-drop-editor-item:not(.cdk-drag-placeholder) {
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1);
}

/** DIALOGS **/
.small-resizable-dialog,
.comments-resizable-dialog,
.medium-resizable-dialog,
.big-resizable-dialog {
  .mat-dialog-container {
    resize: both;
    height: 80vh;
    max-height: 90vh;
    min-width: 460px;
    // min-height: 400px;
    min-height: 200px;
  }
}
.small-resizable-dialog .mat-dialog-container {
  width: 300px;
}
.comments-resizable-dialog .mat-dialog-container {
  width: 460px;
}
.medium-resizable-dialog .mat-dialog-container {
  width: 600px;
}
.big-resizable-dialog .mat-dialog-container {
  width: 800px;
  min-width: 610px;
}
.mat-dialog-content.full-dialog-content {
  max-height: none;
  height: calc(100% - 32px);
  overflow: hidden;
}
mat-dialog-container {
  position: relative;

  .dialog-drag-handle {
    position: absolute;
    top: 0;
    right: 40px;
    margin: 0;
    border-radius: 0;
    svg {
      opacity: 0.3;
    }
    .mat-button-focus-overlay {
      display: none;
    }
  }
  .close-dialog-button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    border-radius: 0;
  }
}

/** DROPDOWN MENU **/
.drop-down-menu {
  button {
    height: 28px;
    font-size: 12px !important;
    line-height: 28px;
    &.active {
      font-weight: 500;
    }
    &.dictionary-btn {
      height: 28px;
      line-height: 28px;
      font-size: 12px;
    }
  }

  .menu-separator {
    width: calc(100% - 30px);
    height: 1px;
    margin: 5px auto;
  }

  .button-group {
    user-select: none;
    margin: 3px 0;

    .title {
      float: left;
      padding: 0px 15px;
      width: 100%;
      font-weight: 500;
      font-size: 12px;
    }
    mat-radio-group {
      .mat-radio-container {
        transform: scale(0.8);
        margin: 0 -2px;
      }
    }
  }

  .menu-select-wrap {
    display: flex;
    align-items: center;

    .label {
      font-size: 12px;
      padding: 0 6px 0 16px;
    }
    mat-form-field {
      width: 100%;
      margin-bottom: -16px;
      font-size: 12px;
      line-height: 28px;
      .mat-form-field-underline {
        display: none;
      }
      .mat-form-field-infix {
        border: none;
        padding: 0;
      }
    }
  }

  &.context-drop-down-menu {
    width: 160px;
  }
}

/** KOMENTET DIALOG **/

.komentet-wrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  padding: 0 24px;

  .bottom {
    mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        margin-bottom: -1.34375em;
      }
    }
  }
  .content {
    flex: 1;
    overflow-y: overlay;
    padding: 0 25px;
    margin: 0 -24px;
    display: flex;
    flex-direction: column-reverse;

    .items {
      .group {
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 1px 0px;
          user-select: none;
          align-items: flex-start;

          .operatori {
            display: none;
            font-size: 12px;
          }
          .pershkrimi-wrap {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 5px;

            .edited {
              font-size: 18px;
              width: 18px;
              height: 18px;
            }
            .actions {
              visibility: hidden;
              opacity: 0;
            }
            &:hover {
              .actions {
                visibility: visible;
                opacity: 1;
              }
            }
          }
          .pershkrimi {
            padding: 10px 14px;
            max-width: calc(100% - 115px);
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-top-right-radius: 14px;
            border-bottom-right-radius: 14px;
          }

          &:first-child {
            .operatori {
              display: block;
            }
            .pershkrimi {
              border-top-left-radius: 14px;
            }
          }
          &:last-child {
            .pershkrimi {
              border-bottom-left-radius: 14px;
            }
          }
          &.self {
            align-items: flex-end;

            .pershkrimi-wrap {
              flex-direction: row-reverse;
            }
            .pershkrimi {
              border-top-left-radius: 14px;
              border-bottom-left-radius: 14px;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
            &:first-child {
              .operatori {
                display: block;
              }
              .pershkrimi {
                border-top-right-radius: 14px;
              }
            }
            &:last-child {
              .pershkrimi {
                border-bottom-right-radius: 14px;
              }
            }

            .actions {
              display: flex;
            }
          }

          &:first-child {
            padding-top: 5px;
          }
          &:last-child {
            padding-bottom: 5px;
          }
        }
      }
    }
    .loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Workaround: Tooltip hide animation appears even if show delay not reached (https://github.com/angular/components/issues/24614)
.mat-tooltip-hide {
  display: none !important;
}
