@import '~@angular/material/theming';

@mixin njoftimet-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-njoftimet {
    .njoftimet {
      .njoftimi {
        .inner {
          background-color: mat-color($background, card);
        }
        &.unread {
          .inner {
            background-color: mat-color($background, expansion-panel);
            box-shadow: inset 4px 0px 0 0 mat-color($accent) !important;
          }
        }
        .inner {
          .content {
            .message {
              .pershkrimi {
                color: mat-color($primary);
              }
            }
          }
        }
      }
    }
  }
}
