@import '~@angular/material/theming';

@mixin as-search-and-replace-box-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  as-search-and-replace-box {
    .wrapper {
      background: mat-color($background, dialog);
    }
  }
}

