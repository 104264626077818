@import '~@angular/material/theming';

@mixin word-list-collapsed-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-word-list-collapsed {
    .group-item-expansion-panel {
      background-color: mat-color($background, background);

      .mat-expansion-panel-content {
        background-color: mat-color($background, status-bar);
      }
      &.active {
        .mat-expansion-panel-header {
          background-color: mat-color($background, word-active);
        }
      }
    }
  }
}
