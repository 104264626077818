@import "~@angular/material/theming";

$md-leksikografia-primary: (
  50: #e7eff7,
  100: #c4d8eb,
  200: #9dbedd,
  300: #75a4cf,
  400: #5891c5,
  500: #3a7dbb,
  600: #3475b5,
  700: #2c6aac,
  800: #2560a4,
  900: #184d96,
  A100: #cce0ff,
  A200: #99c0ff,
  A400: #66a1ff,
  A700: #4d92ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-leksikografia-accent: (
    50 : #fcf6ea,
    100 : #f8e8ca,
    200 : #f3d8a7,
    300 : #eec884,
    400 : #ebbd69,
    500 : #e7b14f,
    600 : #e4aa48,
    700 : #e0a13f,
    800 : #dd9836,
    900 : #d78826,
    A100 : #ffffff,
    A200 : #fff0e0,
    A400 : #ffd8ad,
    A700 : #ffcc93,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lex-primary: mat.define-palette($md-leksikografia-primary);
$lex-accent: mat.define-palette($md-leksikografia-accent);
$lex-warn: mat.define-palette(mat.$red-palette);

// LIGHT THEME
// Light Theme Text
$dark-text: #333333;
$dark-primary-text: $dark-text; //rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-divider: rgba($dark-primary-text, 0.12);
$dark-dividers: darken($light-primary-text, 10%);
$dark-focused: rgba($dark-primary-text, 0.12);
$dark-outline: rgba($dark-primary-text, 0.3);
$dark-outline-thick: rgba($dark-text, 0.87);
$dark-outline-disabled: rgba($dark-primary-text, 0.06);

$mat-light-theme-foreground: (
  base: #333333,
  divider: $dark-divider,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: #333333,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
  tooltip: #ffffff,
  outline: $dark-divider,
  outline-thick: $dark-outline-thick,
  outline-disabled: $dark-outline-disabled
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-2: darken($light-background, 2%);
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$light-bg-lighter-10: lighten($light-background, 10%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$light-primary-50: lighten(mat-color($lex-primary), 50%);
$light-primary-40: lighten(mat-color($lex-primary), 40%);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-5,
  app-bar: $light-background,
  hover: $light-bg-darker-5,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  locked-word: $light-bg-darker-10,
  locked-active-word: $light-bg-darker-20,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
  scrollbar-track: $light-bg-darker-10,
  scrollbar-thumb: $light-bg-darker-20,
  scrollbar-thumb-hover: $light-bg-darker-30,
  gray-wrap: $light-bg-darker-5,
  expansion-panel: $light-bg-darker-2,
  word-hover: $light-primary-50,
  word-active: $light-primary-40,
  mark: yellow,
  mark-selected: orange
);

$lex-theme: (
  primary: $lex-primary,
  accent: $lex-accent,
  warn: $lex-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);

// DARK THEME
// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-divider: rgba($light-primary-text, 0.12);
$light-dividers: darken($light-primary-text, 75%);
$light-focused: rgba($light-primary-text, 0.12);
$light-outline: rgba($light-primary-text, 0.3);
$light-outline-disabled: rgba($light-primary-text, 0.15);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-divider,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
  tooltip: $light-text,
  outline: $light-outline,
  outline-thick: $light-primary-text,
  outline-disabled: $light-outline-disabled
);

// Dark bg
$dark-background: #09121b;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

$dark-primary-35: darken(mat-color($lex-primary), 35%);
$dark-primary-25: darken(mat-color($lex-primary), 25%);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-5,
  app-bar: $dark-bg-lighter-5,
  hover: $dark-bg-lighter-5,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  locked-word: darken($dark-background, 4%),
  locked-active-word: darken($dark-background, 2%),
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
  scrollbar-track: $dark-bg-lighter-10,
  scrollbar-thumb: $dark-bg-lighter-20,
  scrollbar-thumb-hover: $dark-bg-lighter-30,
  gray-wrap: $dark-background,
  expansion-panel: $dark-bg-lighter-10,
  word-hover: $dark-primary-35,
  word-active: $dark-primary-25,
  mark: #ffffff36,
  mark-selected: #806027
);

$lex-dark-theme: (
  primary: $lex-primary,
  accent: $lex-accent,
  warn: $lex-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// Create your Sass color vars (will be available in all the project)
$primary: mat-color($lex-primary);
$accent: mat-color($lex-accent);
$warn: mat-color($lex-warn);
